// quill リッチテキスト
@import '~quill/dist/quill.snow.css';

// チャットボットのコンテンツが印刷されないようにする
@media print {
  // 特定するIDが振られているものの、いつIDが変わるか分からないので番号指定以外でclassをセレクトする
  div[class*='widget-'].loaded {
    display: none !important;
  }
}

@import './scss/thirdparty.scss';
@import './scss/variables.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

body {
  height: 100%;
  background-color: $jgrants-background-color;
  word-wrap: break-word;
  background-position-y: calc(64px + 1.7em);
  color: $jgrants-text-color;
}

.application-background {
  background-image: url(/assets/images/top-image.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.application-content {
  display: grid;
  padding: 0 1rem 0 1rem;
  font-size: 16px;

  > .application-content-container {
    justify-self: center;
    align-self: center;
    max-width: $jgrants-content-max-width;
    width: 100%;
    font-size: 16px;

    > h1,
    > .page-title {
      padding-left: 1rem;
      border-left: 4px solid $primary-color;
      font-size: 2em;
      margin: 0.67em 0;
      font-weight: bold;
      line-height: 1.15;
    }

    > mat-card {
      padding: 2rem;
    }

    .application-card-shadow {
      box-shadow: $jgrants-card-shadow;
    }
  }
}

.application-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // FireFox対応
  .mat-accordion {
    width: 100%;
  }

  .application-form-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .application-form-item {
    display: block;
    margin: 0 0 1rem 10px;

    > .mat-mdc-form-field {
      width: 100%;
    }
    > .mat-form-field {
      width: 100%;
    }

    &__half-width {
      width: calc(50% - 10px);
    }

    &__full-width {
      width: calc(100% - 5px);
    }
  }

  .background-pale-red {
    background-color: palevioletred;
  }

  .background-pale-blue {
    background-color: paleturquoise;
  }

  .background-pale-yellow {
    background-color: palegoldenrod;
  }

  .background-pale-gray {
    background-color: lightgray;
  }
}

.navigation-is-mobile {
  // モバイルモード時は、余白をなくしてスペースを広く使う
  .application-content {
    padding: 0;

    > .application-content-container {
      max-width: calc(100vw);

      > mat-card {
        padding: 1rem 5px;
      }
    }
  }

  // 動的フォームのモバイルモード時はハーフ列をフル列に変化させる
  .application-form-container .application-form-item__half-width {
    width: calc(100% - 5px);
  }

  // フロートボタンバーのフロートを無効にする（Chatbotの表示と被るため）
  .application-footer-sticky-bar {
    position: static;
  }
}

.application-footer-sticky-bar {
  margin: 10px -1rem 0 -1rem; // mat-cardの影のぼかし半径より下げて、横幅を全体幅まで伸ばす
  padding: 0 0 1rem 0;
  background-color: rgba(242, 242, 242, 0.9);
  position: sticky;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 2;

  /* Mobile Safari の再描画条件を緩和する */
  transform: translate3d(0, 0, 0);

  // スティッキーバー内のSubmitボタン
  .application-submit-button[mat-button],
  .application-submit-button[mat-raised-button],
  .application-submit-button[mat-flat-button] {
    margin: 1rem 1rem 0 1rem;
    padding: 0;
    min-width: 100px;
    width: 100%;
    max-width: 200px;
    height: 3rem;
  }
}

.application-search-result-zero {
  margin: 1rem;
}

.application-html-hint-area {
  margin-top: 20px;
  font-size: 0.8rem;
  word-break: break-all; // firefox用
  word-break: break-word;

  > p {
    margin: 0;
    line-height: 1rem;
  }
}

// 動的フォーム項目の複合コントロールのタイトルなどの色を
// mat-form-fieldのDisabled時のカラーと同様にするためにカラーデータをコピーしたもの
.application-form-field-disabled-color {
  color: rgba(0, 0, 0, 0.38);
}

// 動的フォーム項目のカスタム必須マーク
.application-form-field-required-marker:before {
  content: '必須';
  padding: 0.1rem 0.2rem;
  background-color: $accent-color;
  color: $jgrants-button-text-color;
  line-height: 1rem;
  border-radius: 0.15rem;
  font-size: 0.77rem;
  font-style: normal;
}

// materialのエラー時に背景色をエラー文字色と同一にする
.mat-mdc-form-field-error.application-form-field-required-marker:before,
.mat-form-field-invalid .application-form-field-required-marker:before {
  background-color: $warn-color;
}

// 以下のclassはSFDCのリッチテキストでインデントを行ったときに付与されるカスタムインデントスタイルをコピーしたもの
// SFDCではquillを利用しているため、quill固有のclassが付与される可能性がある
@for $i from 1 through 9 {
  .ql-indent-#{$i} {
    padding-left: 1rem * $i;
  }
}

@media print {
  body {
    background-color: white;
  }
}
